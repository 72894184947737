import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

const ServicesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Services" />

      <article className="post-content page-template no-image">
        <div class="post-content-body">
        <p style={{ "text-align": "center" }}>
            Whether your on the hunt for someone to design a PCB for an existing
            case design, your next custom keyboard or anything in between here
            at the EPDesign studio we offer a range of design services to ensure
            you needs are met
          </p>
          <p style={{ "text-align": "center" }}>
            To discuss your project please see our{" "}
            <Link to="/contact">Contacts Page</Link>{" "}
          </p>
          <article class="service-holder">
            <div class="service-grid-container">
              <div class="services-column">
                <Img
                  fixed={data.mcu.childImageSharp.fixed}
                  style={{ filter: "invert(1)" }}
                />
              </div>
              <div class="services-column">
                <h4>PCB Design Services</h4>
                <p>
                  For when you need a new pcb designed or modifications to an
                  existing design. Please see below for a list of our standard
                  capabilities:
                </p>
                <div class="capabilities-list">
                  <ul >
                    <li>AVR or ARM</li>
                    <li>Wireless PCBs</li>
                    <li>MCU Boards</li>
                    <li>Sliders (Linear Potentiometers)</li>
                    <li>Rotary Encoders</li>
                    <li>Per-key lighting/RGB</li>
                    <li>Underglow</li>
                    <li>LED Indicators</li>
                    <li>MX, Alps or Choc</li>
                    <li>Hotswap</li>
                    <li>Splits and Detachable PCBs</li>
                    <li>Oleds</li>
                    <li>For anything not listed please dont hesitate to contact us</li>
                  </ul>
                  <p>All our pcbs also come fitted with ESD protection, overcurrent and overvoltage protection as standard</p>
                </div>
              </div>
            </div>
            <div class="service-grid-container">
              <div class="services-column">
                <Img
                  fixed={data.case.childImageSharp.fixed}
                  style={{ filter: "invert(1)" }}
                />
              </div>
              <div class="services-column">
                <h4>Case Design Services</h4>
                <p>This service is coming soon however we may still be able to help
                  or point you the way to one of our skillful friends so please
                  dont hesitate to get in touch
                </p>
                {/* <p>For when you just need a case designed</p> */}
              </div>
            </div>
          </article>
          <h3>Our Standard Design Process</h3>
        </div>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#e53935", color: "#fff" }}
            iconStyle={{ background: "#e53935", color: "#fff" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z" />
              </svg>
            }
          >
            <h3 className="vertical-timeline-element-title">Contact Us</h3>
            <p style={{ fontSize: "1.4rem" }}>
              Our process starts by a conversation in which a design brief is
              decided upon in order for us truly to understand our clients needs
              and wants.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#D81B60", color: "#fff" }}
            iconStyle={{ background: "#D81B60", color: "rgb(0,0,0)" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="pencil-ruler"
                class="svg-inline--fa fa-pencil-ruler fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M109.46 244.04l134.58-134.56-44.12-44.12-61.68 61.68a7.919 7.919 0 0 1-11.21 0l-11.21-11.21c-3.1-3.1-3.1-8.12 0-11.21l61.68-61.68-33.64-33.65C131.47-3.1 111.39-3.1 99 9.29L9.29 99c-12.38 12.39-12.39 32.47 0 44.86l100.17 100.18zm388.47-116.8c18.76-18.76 18.75-49.17 0-67.93l-45.25-45.25c-18.76-18.76-49.18-18.76-67.95 0l-46.02 46.01 113.2 113.2 46.02-46.03zM316.08 82.71l-297 296.96L.32 487.11c-2.53 14.49 10.09 27.11 24.59 24.56l107.45-18.84L429.28 195.9 316.08 82.71zm186.63 285.43l-33.64-33.64-61.68 61.68c-3.1 3.1-8.12 3.1-11.21 0l-11.21-11.21c-3.09-3.1-3.09-8.12 0-11.21l61.68-61.68-44.14-44.14L267.93 402.5l100.21 100.2c12.39 12.39 32.47 12.39 44.86 0l89.71-89.7c12.39-12.39 12.39-32.47 0-44.86z"
                />
              </svg>
            }
          >
            <h3 className="vertical-timeline-element-title">Initial Design</h3>
            <p style={{ fontSize: "1.4rem" }}>
              Here we produce the initial design and present this to
              the client to ensure our design matches their vision for the project.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#8E24AA", color: "#fff" }}
            iconStyle={{ background: "#8E24AA", color: "rgb(0,0,0)" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="drafting-compass"
                class="svg-inline--fa fa-drafting-compass fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M457.01 344.42c-25.05 20.33-52.63 37.18-82.54 49.05l54.38 94.19 53.95 23.04c9.81 4.19 20.89-2.21 22.17-12.8l7.02-58.25-54.98-95.23zm42.49-94.56c4.86-7.67 1.89-17.99-6.05-22.39l-28.07-15.57c-7.48-4.15-16.61-1.46-21.26 5.72C403.01 281.15 332.25 320 256 320c-23.93 0-47.23-4.25-69.41-11.53l67.36-116.68c.7.02 1.34.21 2.04.21s1.35-.19 2.04-.21l51.09 88.5c31.23-8.96 59.56-25.75 82.61-48.92l-51.79-89.71C347.39 128.03 352 112.63 352 96c0-53.02-42.98-96-96-96s-96 42.98-96 96c0 16.63 4.61 32.03 12.05 45.66l-68.3 118.31c-12.55-11.61-23.96-24.59-33.68-39-4.79-7.1-13.97-9.62-21.38-5.33l-27.75 16.07c-7.85 4.54-10.63 14.9-5.64 22.47 15.57 23.64 34.69 44.21 55.98 62.02L0 439.66l7.02 58.25c1.28 10.59 12.36 16.99 22.17 12.8l53.95-23.04 70.8-122.63C186.13 377.28 220.62 384 256 384c99.05 0 190.88-51.01 243.5-134.14zM256 64c17.67 0 32 14.33 32 32s-14.33 32-32 32-32-14.33-32-32 14.33-32 32-32z"
                />
              </svg>
            }
          >
            <h3 className="vertical-timeline-element-title">
              Design Completion
            </h3>
            <p style={{ fontSize: "1.4rem" }}>
              Following on from further conversation with the client a design is
              finalised ready for the production of a prototype
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#5E35B1", color: "#fff" }}
            iconStyle={{ background: "#5E35B1", color: "rgb(0,0,0)" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="tools"
                class="svg-inline--fa fa-tools fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z"
                />
              </svg>
            }
          >
            <h3 className="vertical-timeline-element-title">
              Prototype Production
            </h3>
            <p style={{ fontSize: "1.4rem" }}>
              At this stage with approval of the design by the client,
              prototypes can be ordered and manufactured
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#3949AB", color: "#fff" }}
            iconStyle={{ background: "#3949AB", color: "rgb(0,0,0)" }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="clipboard"
                class="svg-inline--fa fa-clipboard fa-w-12"
                role="img"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"
                />
              </svg>
            }
          >
            <h3 className="vertical-timeline-element-title">
              Prototype Review
            </h3>
            <p style={{ fontSize: "1.4rem" }}>
              We strive to ensure prototype are delivered to highest
              quality, However at this stage minor adjustments can be made
              here based on our technique view of the product as well as
              your opinions from trialing the design. These changes are done
              here before your design enters any larger production runs
            </p>
          </VerticalTimelineElement>
          {/* <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            icon={<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="flag-checkered" class="svg-inline--fa fa-flag-checkered fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M243.2 189.9V258c26.1 5.9 49.3 15.6 73.6 22.3v-68.2c-26-5.8-49.4-15.5-73.6-22.2zm223.3-123c-34.3 15.9-76.5 31.9-117 31.9C296 98.8 251.7 64 184.3 64c-25 0-47.3 4.4-68 12 2.8-7.3 4.1-15.2 3.6-23.6C118.1 24 94.8 1.2 66.3 0 34.3-1.3 8 24.3 8 56c0 19 9.5 35.8 24 45.9V488c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24v-94.4c28.3-12.1 63.6-22.1 114.4-22.1 53.6 0 97.8 34.8 165.2 34.8 48.2 0 86.7-16.3 122.5-40.9 8.7-6 13.8-15.8 13.8-26.4V95.9c.1-23.3-24.2-38.8-45.4-29zM169.6 325.5c-25.8 2.7-50 8.2-73.6 16.6v-70.5c26.2-9.3 47.5-15 73.6-17.4zM464 191c-23.6 9.8-46.3 19.5-73.6 23.9V286c24.8-3.4 51.4-11.8 73.6-26v70.5c-25.1 16.1-48.5 24.7-73.6 27.1V286c-27 3.7-47.9 1.5-73.6-5.6v67.4c-23.9-7.4-47.3-16.7-73.6-21.3V258c-19.7-4.4-40.8-6.8-73.6-3.8v-70c-22.4 3.1-44.6 10.2-73.6 20.9v-70.5c33.2-12.2 50.1-19.8 73.6-22v71.6c27-3.7 48.4-1.3 73.6 5.7v-67.4c23.7 7.4 47.2 16.7 73.6 21.3v68.4c23.7 5.3 47.6 6.9 73.6 2.7V143c27-4.8 52.3-13.6 73.6-22.5z"/></svg>}
          /> */}
        </VerticalTimeline>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mcu: file(relativePath: { eq: "mcu.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    case: file(relativePath: { eq: "case.png" }) {
      childImageSharp {
        fixed(width: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicesPage location={props.location} data={data} {...props} />
    )}
  />
)
